<template>
  <div class="header">
    <nav class="header-nav">
      <div class="container container-default !flex-row items-center justify-between md:justify-normal px-4">
        <div class="w-auto md:w-3/12 md:flex">
          <ul class="left-menu">
            <li>
              <a
                :class="[
                  'nav-item',
                  is_active(['/routes', '/route']) ? 'active' : ''
                ]"
                :href="url({ path: '/routes' })"
                title="Ruta"
              >
                <i class="fi fi-rr-playing-cards"></i>
                {{ gt('navigation.routes') }}
              </a>
            </li>
            <li>
              <a
                :class="['nav-item', is_active(['/blog']) ? 'active' : '']"
                :href="url({ path: '/blog' })"
                title="Blog"
              >
                <i class="fi fi-rr-book-open-cover"></i>
                {{ gt('navigation.posts') }}
              </a>
            </li>
          </ul>
        </div>

        <div class="w-auto md:w-6/12 flex justify-center">
          <a :href="url({ path: '/' })" title="Inicio">
            <img
              :src="logo_text"
              class="h-10 hidden md:block cursor-pointer"
              alt="Logo de memeonlyme"
              title="Logo de memeonlyme"
              aria-label="Logo de memeonlyme"
            />

            <img
              :src="logo_icon"
              class="h-10 rounded-lg block md:hidden cursor-pointer"
              alt="Logo de memeonlyme"
              title="Logo de memeonlyme"
              aria-label="Logo de memeonlyme"
            />
          </a>
        </div>

        <div class="w-3/12 hidden md:flex">
          <ul class="flex w-full gap-1 list-none items-center justify-end">
            <li v-if="!User.is_logged">
              <a
                class="nav-item active"
                :href="url({ path: '/login' })"
                title="Accede"
              >
                <i class="fi fi-rr-user leading-[0] text-xl"></i>
                {{ gt('navigation.login') }}
              </a>
            </li>

            <li v-if="User.is_logged">
              <span class="nav-item active" @click="logout()">
                <i class="fi fi-rr-user leading-[0] text-xl"></i>
                {{ user_name }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>

  <!-- Player -->
  <player_component></player_component>

  <!--  Popups -->
  <transition name="fade">
    <popup_component v-if="popup.active" :notification="popup">
      <template v-if="popup?.title" #title>
        {{ popup.title }}
      </template>

      <template #message>
        {{ popup.message }}
      </template>
    </popup_component>
  </transition>
</template>

<script setup>
  // Components
  import { defineAsyncComponent } from 'vue'

  const player_component = defineAsyncComponent(
    () => import('@/components/app/player/index.vue')
  )

  const popup_component = defineAsyncComponent(
    () => import('@/components/commons/popup.vue')
  )

  // Stores
  import UserStore from '@/stores/user'
  import AppStore from '@/stores/app'
  import { useI18n } from 'vue-i18n'

  // Images
  import logo_text from '@/assets/logos/logo-text-black.png'
  import logo_icon from '@/assets/logos/logo-icon.jpeg'

  // Lib
  import { computed } from 'vue'
  import url from '@/helpers/url'

  // Variables
  const User = UserStore()
  const { popup } = AppStore()

  const { t } = useI18n()
  const gt = (key, args = {}) => t(`components.template.header.${key}`, args)

  // Computed vars
  const user_name = computed(() => {
    return User.user?.name || User.user?.email || User.user?.id || '---'
  })

  // Method
  function is_active(path = []) {
    return path.some((route) => {
      if (route === '/') {
        return window.location.pathname === '/'
      }

      return window.location.pathname.startsWith(route)
    })
  }

  function logout() {
    User.logout()
    window.location.href = url({ path: '/logout' })
  }
</script>
